import { defineStore } from 'pinia'
import { ref } from 'vue'
import { favoritesApi } from '@/services/favorites.service'

export const useFavoritesStore = defineStore('favoritesStore', () => {

  const count = ref<number>(0)

  function getCount() {
    favoritesApi.counter.execute().then(({ item }) => {
      count.value = item.count
    })
  }

  return {
    count,
    getCount,
  }
})

import type { RouteRecordRaw } from 'vue-router'
import { route } from '@/router/utils'
import type { Options } from '@/router'
import { i18n } from '@/i18n'
import { accessPermission } from '@/modules/user/permissions'
import { IS_MANAGER } from '@/modules/user/accessTokens'

export const REFERRALS_STATISTICS = 'cabinet:referrals-statistics'

const List = () => import('./views/List/index.vue')

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { t: $t } = i18n.global
  return [
    route('', List, REFERRALS_STATISTICS, {
      meta: {
        title: () => $t('Supermanager Statistics'),
        ...accessPermission([IS_MANAGER], options)
      },
    }),
    // { path: '', redirect: { name: REFERRALS_STATISTICS } },
  ]
}

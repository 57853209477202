import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_nowrap g-row--align_center sk-space_pl sk-space_pl--0" }
const _hoisted_2 = {
  key: 0,
  class: "g-cell g-cols g-cols--narrow"
}
const _hoisted_3 = { class: "ds-inliner ds-inliner--size_lg" }
const _hoisted_4 = { class: "ds-inliner__body" }
const _hoisted_5 = { class: "g-cell g-cols g-cols--auto" }
const _hoisted_6 = { class: "g-row g-row--appearance_nowrap g-row--align_center sk-space_pl sk-space_pl--0" }
const _hoisted_7 = {
  key: 0,
  class: "g-cell g-cols g-cols--narrow"
}
const _hoisted_8 = { class: "ds-inliner ds-inliner--size_xl" }
const _hoisted_9 = { class: "ds-inliner__body" }
const _hoisted_10 = { class: "g-cell g-cols g-cols--auto ellipsis-content ellipsis-content--1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ControlSelect = _resolveComponent("ControlSelect")!

  return (_openBlock(), _createBlock(_component_ControlSelect, {
    name: $props.filter.slug,
    styling: $props.styling,
    modelValue: $setup.internal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.internal) = $event)),
    onSelect: $setup.updateValue,
    onRemove: $setup.updateValue,
    "track-by": 'slug',
    "label-by": 'title',
    options: $setup.sortedOptions,
    searchable: true
  }, {
    option: _withCtx(({ option }) => [
      _createElementVNode("div", _hoisted_1, [
        (option.code2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["fi fis flag-wrap", `fi-${(option.code2).toLowerCase()}`])
                  }, null, 2)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", null, _toDisplayString(" " + option.title), 1)
        ])
      ])
    ]),
    singleLabel: _withCtx(({ option }) => [
      _createElementVNode("div", _hoisted_6, [
        (option.code2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["fi fis flag-wrap", `fi-${(option.code2).toLowerCase()}`])
                  }, null, 2)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("span", null, _toDisplayString(" " + option.title), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["name", "styling", "modelValue", "options"]))
}
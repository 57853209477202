import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"
import _imports_0 from '@/assets/default_placeholder.svg'


const _hoisted_1 = { class: "search-view" }
const _hoisted_2 = { class: "search-view__input" }
const _hoisted_3 = { class: "search-view__category-wrap" }
const _hoisted_4 = ["title"]
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = {
  class: "ds-inliner ds-inliner--size_xs",
  "aria-hidden": "true"
}
const _hoisted_7 = {
  key: 0,
  class: "control-input__element control-input__element--attachment_append"
}
const _hoisted_8 = {
  key: 0,
  class: "search-view__loading"
}
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = { class: "search-view__list-wrap" }
const _hoisted_11 = { class: "search-view__list" }
const _hoisted_12 = { class: "search-preview" }
const _hoisted_13 = ["href", "onKeydown"]
const _hoisted_14 = {
  key: 0,
  class: "ds-aspect-ratio ds-aspect-ratio--ratio_16x9 ds-aspect-ratio--appearance_cuted ds-aspect-ratio--appearance_covered search-preview__image"
}
const _hoisted_15 = ["srcset"]
const _hoisted_16 = ["src", "alt"]
const _hoisted_17 = {
  key: 1,
  class: "ds-aspect-ratio ds-aspect-ratio--ratio_16x9 ds-aspect-ratio--appearance_covered ds-aspect-ratio--appearance_cuted search-preview__image img-placeholder"
}
const _hoisted_18 = { class: "search-preview__content" }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = { class: "search-preview__id" }
const _hoisted_21 = { class: "search-preview__detail" }
const _hoisted_22 = { class: "ds-caption ds-caption--size_md ds-caption--appearance_lh_sm ds-caption--appearance_bold" }
const _hoisted_23 = { class: "ds-caption ds-caption--size_xs ds-caption--appearance_bold" }
const _hoisted_24 = { class: "ds-caption ds-caption--size_2xs" }
const _hoisted_25 = { class: "search-view__footer" }
const _hoisted_26 = ["href"]
const _hoisted_27 = {
  key: 1,
  class: "search-view__empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_close = _resolveComponent("i-close")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_ControlInput = _resolveComponent("ControlInput")!
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ControlInput, {
        ref: "inputRef",
        styling: "accent",
        id: 'header-search-input',
        name: "header-search-input",
        "start-icon": 'i-search',
        "icon-size": ['lg'],
        placeholder: _ctx.$t('Search'),
        size: ['md'],
        focus: $setup.isFocused,
        value: $setup.searchValue,
        onInput: $setup.handleInput,
        onFocus: $setup.handleFocus,
        onBlur: $setup.handleInputBlur,
        onKeydown: [
          _withKeys(_withModifiers($setup.handleDown, ["prevent"]), ["down"]),
          _withKeys(_withModifiers($setup.handleUp, ["prevent"]), ["up"])
        ]
      }, _createSlots({
        append: _withCtx(() => [
          ($setup.searchValue || $setup.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                ($setup.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[1] || (_cache[1] = [
                      _createElementVNode("div", { class: "loading-wrap" }, [
                        _createElementVNode("div", { class: "loading" })
                      ], -1)
                    ])))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "search-view__clear-button",
                      type: "button",
                      "aria-label": _ctx.$t('Close'),
                      onClick: $setup.resetSearch
                    }, [
                      _createVNode(_component_AppIcon, {
                        "aria-hidden": "true",
                        name: "i-close",
                        size: ['sm', 'lg-xl']
                      })
                    ], 8, _hoisted_9))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, [
        ($setup.category)
          ? {
              name: "prepend",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", {
                    class: "search-view__category-title",
                    title: $setup.category.title
                  }, _toDisplayString(_ctx.$t("Category")), 9, _hoisted_4),
                  _createElementVNode("button", {
                    class: "search-view__category-clear",
                    type: "button",
                    "aria-label": _ctx.$t('Close'),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.resetCategory()))
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_i_close, { class: "ds-inliner__body" })
                    ])
                  ], 8, _hoisted_5)
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["placeholder", "focus", "value", "onKeydown"])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_10, [
      ($setup.result?.items?.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.result?.items || [], (item, index) => {
                  return (_openBlock(), _createElementBlock("a", {
                    class: "search-preview__item ds-link",
                    href: item.offerLink,
                    ref_for: true,
                    ref: "itemsRefs",
                    onKeydown: [
                      _withKeys(_withModifiers($setup.handleDown, ["prevent"]), ["down"]),
                      _withKeys(_withModifiers($setup.handleUp, ["prevent"]), ["up"])
                    ]
                  }, [
                    (item.photos[0]?.image)
                      ? (_openBlock(), _createElementBlock("picture", _hoisted_14, [
                          _createElementVNode("source", {
                            type: "image/webp",
                            srcset: item.photos[0].image.webp
                          }, null, 8, _hoisted_15),
                          _createElementVNode("img", {
                            class: "ds-aspect-ratio__body image-cover",
                            src: item.photos[0].image.origin,
                            alt: item.titleGenerate,
                            width: "150",
                            height: "85"
                          }, null, 8, _hoisted_16)
                        ]))
                      : (_openBlock(), _createElementBlock("picture", _hoisted_17, _cache[2] || (_cache[2] = [
                          _createElementVNode("img", {
                            class: "ds-aspect-ratio__body d-block img-placeholder__img",
                            src: _imports_0,
                            loading: "lazy"
                          }, null, -1)
                        ]))),
                    _createElementVNode("span", _hoisted_18, [
                      _createElementVNode("h4", {
                        class: "search-preview__title",
                        innerHTML: item.titleGenerate
                      }, null, 8, _hoisted_19),
                      _createElementVNode("span", _hoisted_20, _toDisplayString(item.id), 1),
                      _createElementVNode("span", _hoisted_21, [
                        _createElementVNode("div", null, [
                          _createElementVNode("p", _hoisted_22, [
                            _createTextVNode(_toDisplayString(_ctx.$n(Number(item.priceMonthly), 'currency')), 1),
                            _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t("/ month")), 1)
                          ])
                        ]),
                        _createElementVNode("div", null, [
                          _createElementVNode("p", _hoisted_24, _toDisplayString($setup.pluralization($setup.FinancingPeriodPlurals, item.defaultPeriod) + " " + _ctx.$t("duration")), 1)
                        ])
                      ])
                    ])
                  ], 40, _hoisted_13))
                }), 256))
              ])
            ]),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("a", {
                class: "ds-link ds-link--styling_primary",
                href: $setup.preparedLink
              }, _toDisplayString(_ctx.$t("Show all results")), 9, _hoisted_26)
            ])
          ], 64))
        : (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_ctx.$t("No results")), 1))
    ], 512), [
      [_vShow, $setup.searchValue && $setup.result && $setup.result?.items && $setup.isShow]
    ])
  ])), [
    [_directive_on_click_outside, $setup.handleBlur]
  ])
}
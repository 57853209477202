import { defineComponent as _defineComponent } from 'vue'
import {
  computed,
  ref,
  type Ref,
  defineAsyncComponent,
  toRefs,
} from 'vue'
import { odooApi } from '@/services/odoo.service'
import { useI18n } from 'vue-i18n'
import LoadingWrapper from '@/components/ui/LoadingWrapper.vue'

import applyingForFinancingFirst from './applyingForFinancingFirst.vue'
import { financingApplicationsApi } from '@/services/financingApplications.service'
import { clone } from 'ramda'
import { catchFormErrors, defaultValidatorErrorsParser, useSubmit } from '@/composables/useSubmit';
import { IS_AGENT, IS_CUSTOMER, IS_DEALER, IS_MANAGER } from '@/modules/user/accessTokens'
import { GtagEvents } from '@/const'
import { isPermittedSimple } from '@/modules/user/permissions'
export interface Props {
  stepFirst: string
  stepSecond: string
  stepThird: string
  step: number
  videoUrl: string
  videoPreview: string
  videoPreviewAlt?: string
  offerId: string
  financingApplicationId?: string
  targetCountryId: string
  downPayment: string
  period: string
  financingType: string
  scheduleType: string
  commercialOfferId: string
  defaultCountry: {
    id: string | number
    title: string
  } | null
  countryRegistration: {
    code2: string
    title: string
  } | null
  financingApplicationUuid?: string
  successRedirectUrl: string
  contactEmail: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'applyingForFinancing',
  props: {
    stepFirst: { default: '' },
    stepSecond: { default: '' },
    stepThird: { default: '' },
    step: { default: 0 },
    videoUrl: { default: '' },
    videoPreview: { default: '' },
    videoPreviewAlt: { default: '' },
    offerId: { default: '' },
    financingApplicationId: { default: '' },
    targetCountryId: { default: '' },
    downPayment: { default: '' },
    period: { default: '' },
    financingType: { default: '' },
    scheduleType: { default: '' },
    commercialOfferId: { default: '' },
    defaultCountry: {},
    countryRegistration: { default: null },
    financingApplicationUuid: { default: '' },
    successRedirectUrl: {},
    contactEmail: { default: '' },
    firstName: {},
    lastName: {},
    email: {},
    phone: {}
  },
  emits: ["close"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const LoadingComponentWrapper = {
  component: { LoadingWrapper },
  template: `<loading-wrapper :loading="true" />`,
}

const applyingForFinancingSecond = defineAsyncComponent({
  loader: () => import('./applyingForFinancingSecond.vue'),
  loadingComponent: LoadingComponentWrapper,
})

const props = __props

const stepDescription = ref([
  props.stepFirst,
  props.stepSecond,
  props.stepThird,
])

const { t } = useI18n()

const emit = __emit

const { IS_SUPER } = window

const stepper: Ref = ref(null)
const stepperContent: Ref = ref(null)
const initial: Ref = ref({
  commercialOfferId: props.commercialOfferId,
  offerId: props.offerId,
  id: props.financingApplicationId,
  uuid: props.financingApplicationUuid,
  targetCountryId: props.targetCountryId,
  downPayment: props.downPayment,
  period: props.period,
  financingType: props.financingType,
  scheduleType: props.scheduleType,
  countryRegistration: props.countryRegistration,
  contactFirstName: (!IS_SUPER && isPermittedSimple([IS_CUSTOMER])) ? props.firstName : '',
  contactLastName: (!IS_SUPER && isPermittedSimple([IS_CUSTOMER])) ? props.lastName : '',
  contactEmail: (!IS_SUPER && isPermittedSimple([IS_CUSTOMER])) ? props.email : '',
  confirmContactEmail: (!IS_SUPER && isPermittedSimple([IS_CUSTOMER])) ? props.email : '',
  contactPhone: (!IS_SUPER && isPermittedSimple([IS_CUSTOMER])) ? props.phone : '',
})


const currentStep: Ref = ref(props.step)
const isLoad: Ref<boolean> = ref(false)
const result: Ref<Record<string, any>> = ref({})
const error: Ref<string> = ref('')

const stepsConfig = ref([
  {
    name: 'firstStep',
    title: t('Contact details'),
    component: applyingForFinancingFirst,
    inWork: true,
    disabled: false,
    isValid: true,
  },
  {
    name: 'secondStep',
    title: t('Company details'),
    component: applyingForFinancingSecond,
    inWork: false,
    disabled: true,
    isValid: true,
    beforeEnter: () => {},
  },
  {
    name: 'thirdStep',
    title: t('Open banking'),
    inWork: false,
    disabled: true,
    isValid: true,
    beforeEnter: () => {},
  },
])

const setFlags = (step) => {
  currentStep.value = step
  stepperContent.value.scrollIntoView({ block: 'start', behavior: 'smooth' })
}

const saveAndGoNext = (data: Record<string, any>, step: number) => {
  initial.value = {
    ...initial.value,
    ...data,
  }
  stepper.value.nextStep(currentStep.value + 1)
  stepperContent.value.scrollIntoView({ block: 'start', behavior: 'smooth' })
  if (step > 1) {
    getBanks()
  }
}

// odoo
const banksList = ref([])
const banksLoading = ref(false)
const banksFormLoading = ref(false)
const banksFormRef = ref(null)
const captchaRef = ref('')

const getCaptcha = async () => {
  banksFormLoading.value = true
  await captchaRef.value.execute()
}

const getBankLinkForm = ref({
  bank: null,
  recaptcha: '',
})

function getBanks() {
  banksLoading.value = true
  const params = {
    method: 'get',
    path: `/banks/${initial.value.countryRegistration?.code2?.toLowerCase?.()}`,
  }
  return odooApi.proxyRequest
    .execute({}, params)
    .then(({ data: { item } }) => {
      banksList.value = item
      return item
    })
    .finally(() => {
      banksLoading.value = false
    })
}
if (currentStep.value > 1) {
  getBanks()
}

const saveCaptcha = async (val, setErrors) => {
  getBankLinkForm.value.recaptcha = val
  await submit(getBankLinkForm.value, banksFormRef)
}

function submit(form, control) {
  const data = clone(getBankLinkForm.value)
  data.institutionId = data.bank.id
  data.financingApplication = initial.value.uuid
  return useSubmit(send(data, control), control, defaultValidatorErrorsParser).catch(e => {
    control.setFieldError?.('nonFieldErrors', t('Form has an error. Please, contact us!'))
  })
}
function send(data, control) {
  return financingApplicationsApi.getBankLink.execute({}, data)
    .then(({ data: { item } }) => {
      if (item.link) {
        bankLink.value = item.link
      } else {
        control.setFieldError?.('nonFieldErrors', t('Form has an error. Please, contact us!'))
      }
    }).finally(() => {
      captchaRef.value.reset()
      banksFormLoading.value = false
    })
}
const bankLink = ref('')
function redirectToBankLink() {
  window.dataLayer = window.dataLayer || []
  let currentEvent = GtagEvents.online_banking_request
  if (isPermittedSimple([IS_AGENT])) {
    currentEvent = GtagEvents.agent_online_banking_request
  } else if (isPermittedSimple([IS_DEALER])) {
    currentEvent = GtagEvents.dealer_online_banking_request
  } else if (isPermittedSimple([IS_MANAGER])) {
    currentEvent = GtagEvents.manager_online_banking_request
  }
  window.dataLayer.push({
    event: currentEvent,
    email: props.contactEmail || initial.value?.contact?.contactEmail,
  })
  window.location.href = bankLink.value

}

const __returned__ = { LoadingComponentWrapper, applyingForFinancingSecond, props, stepDescription, t, emit, IS_SUPER, stepper, stepperContent, initial, currentStep, isLoad, result, error, stepsConfig, setFlags, saveAndGoNext, banksList, banksLoading, banksFormLoading, banksFormRef, captchaRef, getCaptcha, getBankLinkForm, getBanks, saveCaptcha, submit, send, bankLink, redirectToBankLink, computed, ref, defineAsyncComponent, toRefs, get odooApi() { return odooApi }, get useI18n() { return useI18n }, LoadingWrapper, applyingForFinancingFirst, get financingApplicationsApi() { return financingApplicationsApi }, get clone() { return clone }, get catchFormErrors() { return catchFormErrors }, get defaultValidatorErrorsParser() { return defaultValidatorErrorsParser }, get useSubmit() { return useSubmit }, get IS_AGENT() { return IS_AGENT }, get IS_CUSTOMER() { return IS_CUSTOMER }, get IS_DEALER() { return IS_DEALER }, get IS_MANAGER() { return IS_MANAGER }, get GtagEvents() { return GtagEvents }, get isPermittedSimple() { return isPermittedSimple } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
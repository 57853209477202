import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "catalog-brand-model-widget" }
const _hoisted_2 = {
  key: 0,
  class: "sk-space_mt sk-space_mt--3"
}
const _hoisted_3 = { class: "g-row g-row--justify_between g-row--space_none" }
const _hoisted_4 = { class: "g-cell g-cols" }
const _hoisted_5 = { class: "ds-caption ds-caption--size_xs ds-caption--color_accent" }
const _hoisted_6 = { class: "g-cell g-cols" }
const _hoisted_7 = { class: "ds-caption ds-caption--size_xs ds-caption--color_accent" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.internal, (brandModels, index) => {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["BrandModelPair"], {
          available: $props.available,
          "available-models": $props.availableModels,
          "model-value": $setup.internal[index],
          "selected-brand-ids": $setup.selectedBrandIds,
          "onUpdate:modelValue": ($event: any) => ($setup.onUpdate($event, index)),
          onRemove: ($event: any) => ($setup.onUpdate($event, index)),
          brands: $setup.filteredChoices,
          "is-hide-unavailable": $props.isHideUnavailable,
          styling: $props.styling
        }, null, 8, ["available", "available-models", "model-value", "selected-brand-ids", "onUpdate:modelValue", "onRemove", "brands", "is-hide-unavailable", "styling"])
      ]),
      ($props.config.canAddMore)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, [
                  ($setup.internal.length - 1 === index && !$setup.hasAnyEmpty)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "ds-link ds-link--styling_primary",
                        type: "button",
                        onClick: $setup.addPair
                      }, _toDisplayString(_ctx.$t("Add more")), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, [
                  ($setup.internal.length > 1)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "ds-link ds-link--styling_accent",
                        type: "button",
                        onClick: ($event: any) => ($setup.deletePair(index))
                      }, _toDisplayString(_ctx.$t("Delete")), 9, _hoisted_8))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ], 64))
  }), 256))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "control-descriptor" }
const _hoisted_2 = { class: "control-descriptor__element" }
const _hoisted_3 = { class: "control-descriptor" }
const _hoisted_4 = { class: "control-descriptor__element" }
const _hoisted_5 = {
  key: 0,
  class: "ds-caption ds-caption--size_xs ds-caption--color_danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ControlLabel = _resolveComponent("ControlLabel")!
  const _component_ControlSelect = _resolveComponent("ControlSelect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ControlLabel, {
        "input-label": _ctx.$t('Brand'),
        for: $setup.brandId
      }, null, 8, ["input-label", "for"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ControlSelect, {
          name: 'brand',
          styling: $props.styling,
          modelValue: $setup.brand,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.brand) = $event)),
          "track-by": 'slug',
          "label-by": 'title',
          options: $setup.brands || [],
          searchable: true,
          id: $setup.brandId
        }, {
          option: _withCtx(({ option }) => [
            _createElementVNode("span", {
              class: _normalizeClass({ 'select-option-like-disabled': option.isDisabled})
            }, _toDisplayString(option.title), 3)
          ]),
          _: 1
        }, 8, ["styling", "modelValue", "options", "id"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ControlLabel, {
        "input-label": _ctx.$t('Model'),
        for: $setup.modelId
      }, null, 8, ["input-label", "for"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ControlSelect, {
          name: 'brand',
          styling: $props.styling,
          modelValue: $setup.model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.model) = $event)),
          "track-by": 'slug',
          "label-by": 'title',
          options: $setup.models || [],
          searchable: true,
          id: $setup.modelId
        }, {
          option: _withCtx(({ option }) => [
            _createElementVNode("span", {
              class: _normalizeClass({ 'select-option-like-disabled': option.isDisabled})
            }, _toDisplayString(option.title), 3)
          ]),
          noOptions: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString($setup.brand ? _ctx.$t('List is empty') : _ctx.$t('Select brand first')), 1)
          ]),
          _: 1
        }, 8, ["styling", "modelValue", "options", "id"])
      ])
    ]),
    ($setup.isShowError)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t("Same brand already selected. Please, select model")), 1))
      : _createCommentVNode("", true)
  ], 64))
}
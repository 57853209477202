import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "catalog-input-range-widget" }
const _hoisted_2 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_VueSlider = _resolveComponent("VueSlider")!
  const _component_ControlInput = _resolveComponent("ControlInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ControlInput, {
      name: $props.filter.slug,
      size: ['md'],
      styling: $props.styling,
      type: "number",
      value: $setup.internal,
      onInput: _cache[1] || (_cache[1] = ($event: any) => ($setup.debounceInput($event.target.value))),
      min: $props.filter?.min || 0,
      max: $props.filter?.max || 1
    }, {
      endIcon: _withCtx(() => [
        ($setup.internal)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "ds-link",
              type: "button",
              "aria-label": _ctx.$t('clear'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.updateValue(undefined)))
            }, [
              _createVNode(_component_AppIcon, {
                "aria-hidden": "true",
                name: "i-close",
                size: ['sm', 'lg-xl']
              })
            ], 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      slider: _withCtx(({}) => [
        _createVNode(_component_VueSlider, _mergeProps({ ...$setup.defaultSliderOptions }, {
          silent: true,
          "model-value": $setup.internal,
          "onUpdate:modelValue": $setup.handleUpdate,
          min: $props.filter?.min || 0,
          max: $props.filter?.max || 1
        }), null, 16, ["model-value", "min", "max"])
      ]),
      _: 1
    }, 8, ["name", "styling", "value", "min", "max"])
  ]))
}
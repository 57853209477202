import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "mobile-categories-menu__head" }
const _hoisted_2 = { class: "mobile-categories-menu__list" }
const _hoisted_3 = { class: "mobile-categories-menu__title" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "mobile-categories-menu__count" }
const _hoisted_6 = ["aria-label", "onClick"]
const _hoisted_7 = ["aria-label"]
const _hoisted_8 = { class: "mobile-child-menu-wrap__root" }
const _hoisted_9 = { class: "mobile-child-menu-wrap__root-title" }
const _hoisted_10 = ["aria-label"]
const _hoisted_11 = { class: "mobile-child-menu-wrap__current-wrap" }
const _hoisted_12 = ["aria-label"]
const _hoisted_13 = { class: "mobile-child-menu-wrap__current-title" }
const _hoisted_14 = {
  key: 0,
  class: "mobile-child-menu-wrap__children"
}
const _hoisted_15 = { class: "child-categories" }
const _hoisted_16 = { class: "child-categories__list" }
const _hoisted_17 = { class: "child-categories__item" }
const _hoisted_18 = ["href"]
const _hoisted_19 = {
  key: 0,
  class: "child-categories__item"
}
const _hoisted_20 = ["href"]
const _hoisted_21 = {
  key: 1,
  class: "child-categories__item"
}
const _hoisted_22 = ["href"]
const _hoisted_23 = { class: "mobile-child-menu-wrap__upsell" }
const _hoisted_24 = { class: "mobile-child-menu-wrap__upsell-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "mobile-categories-menu",
    style: _normalizeStyle({"--top": $setup.top})
  }, [
    ($setup.state)
      ? _renderSlot(_ctx.$slots, "search", {
          key: 0,
          open: $setup.toggle
        })
      : _renderSlot(_ctx.$slots, "trigger", {
          key: 1,
          open: $setup.toggle
        }),
    _createElementVNode("div", {
      class: _normalizeClass(["mobile-categories-menu__wrapper", { 'is-active': $setup.state }])
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("Vehicle catalog")), 1),
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.rootCategories, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "mobile-categories-menu__item",
            key: item.id
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("a", {
                class: "mobile-categories-menu__link",
                href: item.link
              }, _toDisplayString(item.title), 9, _hoisted_4)
            ]),
            _createElementVNode("span", _hoisted_5, _toDisplayString(item.count), 1),
            _createElementVNode("button", {
              class: "mobile-categories-menu__chevron",
              "aria-label": _ctx.$t("Expand child"),
              onClick: _withModifiers(($event: any) => ($setup.setActiveRoot(item.id, item.title)), ["prevent"])
            }, null, 8, _hoisted_6)
          ]))
        }), 128))
      ]),
      _createElementVNode("button", {
        class: "mobile-categories-menu__close",
        type: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toggle(false))),
        "aria-label": _ctx.$t('Close menu')
      }, [
        _createVNode(_component_AppIcon, { name: "i-close" })
      ], 8, _hoisted_7)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["mobile-child-menu-wrap", { 'is-active': !!$setup.activeMenu }])
    }, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("Category")), 1),
        _createElementVNode("button", {
          class: "mobile-child-menu-wrap__close-btn",
          type: "button",
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => { $setup.goBack(); }, ["prevent"])),
          "aria-label": _ctx.$t('Close category menu')
        }, [
          _createVNode(_component_app_icon, {
            name: "i-close",
            size: "xl"
          })
        ], 8, _hoisted_10)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("button", {
          class: "mobile-child-menu-wrap__back-btn",
          type: "button",
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => $setup.goBack(), ["prevent"])),
          "aria-label": _ctx.$t('Go back in menu')
        }, [
          _createVNode(_component_app_icon, {
            name: "i-chevron-left",
            size: "xl"
          })
        ], 8, _hoisted_12),
        _createElementVNode("span", _hoisted_13, _toDisplayString($setup.activeRootTitle), 1)
      ]),
      ($setup.activeMenu)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeMenu, (item) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createElementVNode("span", _hoisted_17, [
                    _createElementVNode("a", {
                      class: _normalizeClass(["child-categories__link", { 'child-categories__link--head': !!item.children}]),
                      href: item.link
                    }, _toDisplayString(item.title), 11, _hoisted_18)
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child, i) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (i < 5)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_19, [
                            _createElementVNode("a", {
                              class: "child-categories__link",
                              href: child.link
                            }, _toDisplayString(child.title), 9, _hoisted_20)
                          ]))
                        : _createCommentVNode("", true),
                      (i === 6)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_21, [
                            _createElementVNode("a", {
                              class: "child-categories__link child-categories__link--rest",
                              href: item.link
                            }, _toDisplayString(_ctx.$t("View all")), 9, _hoisted_22)
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 256))
                ]))
              }), 256))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _renderSlot(_ctx.$slots, "banner")
        ])
      ])
    ], 2)
  ], 4))
}
export const TEST_DOMAINS = [
  'pl-eska.webcase-dev.com',
  'sk-eska.webcase-dev.com',
  'uk-eska.webcase-dev.com',
]

export const OFFERS_SORT_MAP = {
  up: 'price_monthly_up',
  down: 'price_monthly_down',
}
export const MAXIMUM_VIDEO_IN_OFFER = 5
export const MAXIMUM_PHOTO_IN_OFFER = 25
export const MAXIMUM_SIZE_PHOTO_IN_OFFER = 10 * 1024 * 1024
export const MAXIMUM_SIZE_FILE_VACANCY = 50 * 1024 * 1024

export const LOCAL_STORAGE_OFFERS_VIEW = 'local-storage-offers-view-controller'
export const LOCAL_STORAGE_CABINET_TABLE_VIEW =
  'local-storage-table-view-controller'

type PaginationItemValue = string | number

export type Pagination = {
  limit: PaginationItemValue
  offset: PaginationItemValue
  total: PaginationItemValue
}

export const COMMERCIAL_OFFER_SOURCE_TYPE = {
  cabinet: 'cabinet',
  offer: 'offer',
  financing_service: 'financing_service',
}

export const noticeStatus = {
  UNREAD: 73232,
  READ: 73236,
  IMPORTANT: 20,
}

export const SK_ACCESS_TOKEN_COOKIE_NAME = 'SK:ACCESS_TOKEN_COOKIE'
export const SK_REFRESH_TOKEN_COOKIE_NAME = 'SK:REFRESH_TOKEN_COOKIE'

export const SK_IS_AUTH_COOKIE_NAME = 'SK:IS_AUTH_COOKIE_NAME'

export const SK_BRANDS_LOCAL_STORAGE_KEY = `sk-brands-${window.language}`
export const SK_BRAND_MODELS_LOCAL_STORAGE_KEY = `sk-brand-models-${window.language}`
/**
 * This function validates and returns a valid locale string based on the provided locale and a fallback value.
 * It uses the `Intl.DisplayNames` API to check if the provided locale is valid.
 * If the locale is not valid, it falls back to the provided fallback value.
 * If the locale starts with 'en-', it returns 'en-GB' as a default English locale.
 *
 * @param locale - The locale string to be validated.
 * @param fallback - The fallback locale string to be used if the provided locale is not valid. Default is 'en-GB'.
 * @returns A valid locale string.
 */
function getValidLocale(locale: string, fallback = 'en-GB'): string {
  try {
    const displayNames = new Intl.DisplayNames([locale], { type: 'language' });
    displayNames.of(locale)
    if (locale.startsWith('en-')) {
      return 'en-GB'
    }
    return locale
  } catch (e) {
    return fallback
  }
}

export const CURRENT_LOCALE = getValidLocale(`${
  window.language
}-${window.organization_country_code.toUpperCase()}`)

export const FinancingApplicationStatusGroupKeys = {
  creditDecision: 'creditDecision',
  contract: 'contract',
  payments: 'payments',
  activeDeals: 'activeDeals',
  canceled: 'canceled',
}
export const FinancingApplicationStatusGroup = {
  [FinancingApplicationStatusGroupKeys.creditDecision]: 'credit_decision',
  [FinancingApplicationStatusGroupKeys.contract]: 'contract',
  [FinancingApplicationStatusGroupKeys.payments]: 'payments',
  [FinancingApplicationStatusGroupKeys.activeDeals]: 'active_deals',
  [FinancingApplicationStatusGroupKeys.canceled]: 'canceled',
}

export enum GtagEvents {
  agent_registration = 'agent_registration',
  dealer_registration = 'dealer_registration',
  customer_registration = 'customer_registration',
  referral_agent_registration = 'referral_agent_registration',
  referral_dealer_registration = 'referral_dealer_registration',
  referral_customer_registration = 'referral_customer_registration',
  feedback_form_submission = 'feedback_form_submission',
  partner_form_submission = 'partner_form_submission',
  newsletter_subscription = 'newsletter_subscription',
  commercial_offer_request = 'commercial_offer_request',
  card_commercial_offer_request = '_commercial_offer_request',
  agent_commercial_offer_request = 'agent_commercial_offer_request',
  dealer_commercial_offer_request = 'dealer_commercial_offer_request',
  manager_commercial_offer_request = 'manager_commercial_offer_request',
  financing_request = 'financing_request',
  agent_financing_request = 'agent_financing_request',
  dealer_financing_request = 'dealer_financing_request',
  manager_financing_request = 'manager_financing_request',
  financing_request_step1 = 'financing_request_step1',
  financing_request_step2 = 'financing_request_step2',
  financing_request_step3 = 'financing_request_step3',
  online_banking_request = 'online_banking_request',
  agent_online_banking_request = 'agent_online_banking_request',
  dealer_online_banking_request = 'dealer_online_banking_request',
  manager_online_banking_request = 'manager_online_banking_request',
  equipment_check_request = 'equipment_check_request',
  delivery_cost_calculation_request = 'delivery_cost_calculation_request',
  job_application = 'job_application',
  bonds_guide_request = 'bonds_guide_request',
  bonds_invest_calculate = 'bonds_invest_calculate',
  bonds_invest_request = 'bonds_invest_request',
}

import { r, createSender } from '@/plugins/resource/resource'
import { count } from 'ramda'

const MODEL = 'favorites'

export const favoritesApi = {
  addOffers: r('/add-offers/', MODEL, createSender),
  removeOffers: r('/remove-offers/', MODEL, createSender),
  counter: r('/counter/', MODEL),
}

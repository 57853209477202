import { type App, type DefineComponent, type Plugin } from 'vue'
import { RouteRecordRaw } from 'vue-router'
import { route, prefixRoutes } from '@router/utils'
import type { Options } from '@/router'

import { createRoutes as createProfileRoutes } from './modules/Profile/router'
import { createRoutes as createCommercialOffers } from './modules/CommercialOffer/router'
import { createRoutes as createFinancingApplicationRoutes } from './modules/FinancingApplication/router'
import { createRoutes as createSavedSearchesRoutes } from './modules/SavedSearch/router'
import { createRoutes as createOffersRoutes } from './modules/Offers/router'
import { createRoutes as createFavoritesRoutes } from './modules/Favorites/router'
import { createRoutes as createCollectionsRoutes } from './modules/Collections/router'
import { createRoutes as createReferralsRoutes } from './modules/Referrals/router'
import { createRoutes as createReferralsStatisticsRoutes } from './modules/ReferralsStatistics/router'
// import { createRoutes as createTransactionsRoutes } from './modules/Transactions/router'
import { i18n } from '@/i18n'

import { PROFILE_VIEW } from './routes'

const CabinetView = () => import('./views/CabinetView.vue')
const CabinetInstaller = () => import('./components')
const Route403 = () => import('./views/Route403.vue')
const Route404 = () => import('./views/Route404.vue')

function installer<T extends DefineComponent>(
  View: any,
  Installer: () => Promise<T>,
  app: App<Element>
): () => Promise<Awaited<T>> {
  return function (): Promise<Awaited<T>> {
    return Promise.all([View(), Installer()]).then(function (result) {
      const [ViewComponent, installer] = result
      app.use(installer?.default || installer?.install || installer)
      return ViewComponent
    })
  }
}

export function createRoutes(options: Options): RouteRecordRaw[] {
  const { app } = options
  const { t: $t } = i18n.global
  return [
    route(
      '',
      installer<any>(
        CabinetView,
        CabinetInstaller as unknown as () => Promise<Plugin>,
        app
      ),
      'cabinet',
      {
        children: [
          prefixRoutes('profile/', createProfileRoutes(options)),
          prefixRoutes('offers/', createOffersRoutes(options)),
          prefixRoutes('commercial-offer/', createCommercialOffers(options)),
          prefixRoutes(
            'financing-application/',
            createFinancingApplicationRoutes(options)
          ),
          prefixRoutes('saved-searches/', createSavedSearchesRoutes(options)),
          prefixRoutes('favorites/', createFavoritesRoutes(options)),
          prefixRoutes('collections/', createCollectionsRoutes(options)),
          prefixRoutes('referrals/', createReferralsRoutes(options)),
          prefixRoutes('referrals-statistics/', createReferralsStatisticsRoutes(options)),
          // prefixRoutes('referral/', createReferralRoutes()),
          // prefixRoutes('transactions/', createTransactionsRoutes()),
          // { path: 'permission-denied/', name: 'cabinet:no-access', component: Route403 },
          {
            path: '/:pathMatch(.*)*',
            name: 'cabinet:not-found',
            component: Route404,
            meta: {
              title: () => $t('404'),
            },
          },
          {
            path: '/:pathMatch(.*)*',
            name: 'cabinet:no-access',
            component: Route403,
            meta: {
              title: () => $t('403'),
            },
          },
        ],
        redirect: { name: PROFILE_VIEW },
      }
    ),
  ]
}

export const { ACCESS_TOKENS = [], ALL_ACCESS_TOKENS = {} } = window || {}

export const {
  STAFF: {
    TYPES: { IS_AGENT, IS_DEALER, IS_MANAGER, IS_CUSTOMER },
    SUPERMANAGER: {
      IS_SUPERMANAGER,
    },
    OFFER,
  },
  COLLECTIONS,
  SAVED_SEARCH
} = ALL_ACCESS_TOKENS

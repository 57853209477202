import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, nextTick, ref, watch } from 'vue'
import { useToggle, useScrollLock } from '@vueuse/core'
import { catalogApi } from '@/services/catalog.service'

const HEADER_ID = 'header-wrap'

export default /*@__PURE__*/_defineComponent({
  __name: 'MobileCategoriesMenu',
  props: {
    rootCategories: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const isLocked = useScrollLock(document.body)
const [state, toggle] = useToggle(false)

watch(state, (value: boolean) => {
  isLocked.value = value
})
const header = ref(null)
onMounted(() => {
  header.value = document.getElementById(HEADER_ID)
})
const top = computed(() =>`${(header.value?.offsetTop || 0) + (header.value?.offsetHeight || 0) - 1}px`)

const props = __props

const activeId = ref(null)
const activeRootTitle = ref('asdfasdf')
// const activeRootTitle = computed(() => {
//   return (props?.rootCategories || []).find(
//     (el) => el.id === activeId.value
//   )?.title
// })

const menu = ref([])

async function getAllMenu() {
  const { item } = await catalogApi.categories.menu.execute()
  menu.value = item
}
getAllMenu()

const menus = ref(new Map())
const activeMenu = ref(null)

async function getNestedMenu(id) {
  if (menus.value.has(id)) {
    return menus.value.get(id)
  }
  const item = menu.value.find((el) => el.id === id).children
  menus.value.set(id, item)
  return item
}

async function setActiveRoot(id, title) {
  activeMenu.value = await getNestedMenu(id)
  nextTick(() => {

    activeRootTitle.value = title
    activeId.value = id
  })
}
function goBack() {
  activeMenu.value = null
}


const __returned__ = { isLocked, state, toggle, HEADER_ID, header, top, props, activeId, activeRootTitle, menu, getAllMenu, menus, activeMenu, getNestedMenu, setActiveRoot, goBack, computed, onMounted, nextTick, ref, watch, get useToggle() { return useToggle }, get useScrollLock() { return useScrollLock }, get catalogApi() { return catalogApi } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, computed } from 'vue'
import { omit, map, clone } from 'ramda'

interface Model {
  title: string
  id: number
  slug: string
}

interface Brand {
  title: string
  id: number
  slug: string
  models: Model[]
}
interface BrandModel {
  brand: Brand
  model: Model
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BrandModelPair',
  props: {
    modelValue: {},
    brands: {},
    available: {},
    availableModels: {},
    isHideUnavailable: { type: Boolean },
    selectedBrandIds: {},
    styling: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const sortByAvailability = (a, b) => {
  return a.isDisabled - b.isDisabled
}

const sortByAlpha = (a, b) =>
  (a.value_verbose || a.title || '').localeCompare(b.value_verbose || b.title || '')

const brands = computed(() => {
  const av = (props.available || []).map(([id]) => id)
  let br = []
  if (props.isHideUnavailable) {
    br = props.brands.filter(el => av.includes(el.id))
  } else {
    br = props.brands.map(el => {
      el['isDisabled'] = !av.includes(el.id)
      return el
    })
  }
  return br.sort(sortByAlpha).sort(sortByAvailability)
})

const models = computed(() => {
  const av = (props.availableModels || []).map(([id]) => id)
  let mds = []
  if (brand.value) {
    if (props.isHideUnavailable) {
      mds = clone(brand.value.models).filter(el => av.includes(el.id))
    } else {
      mds = clone(brand.value.models).map(el => {
        el['isDisabled'] = !av.includes(el?.id)
        return el
      })
    }
  }
  return mds.sort(sortByAlpha).sort(sortByAvailability)
})

const brand = ref(props.modelValue.brand)
const model = ref(props.modelValue.model)

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, (c: number) =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
const brandId = ref<string>(uuidv4())
const modelId = ref<string>(uuidv4())

watch(
  () => props.modelValue,
  () => {
    brand.value = props.modelValue.brand || null
    model.value = props.modelValue.model || null
  },
  {
    deep: true,
  }
)

const internal = computed({
  get() {
    return JSON.parse(JSON.stringify(props.modelValue))
  },
  set(val) {
    emit('update:modelValue', JSON.parse(JSON.stringify(val)))
  },
})

watch(brand, (newVal) => {
  if (JSON.stringify(newVal) !== JSON.stringify(props.modelValue.brand)) {
    internal.value = { brand: omit(['isDisabled'], { ...newVal, models: map(omit(['models']), newVal?.models || []) }), model: null }
  }
})
watch(model, (newVal) => {
  if (JSON.stringify(newVal) !== JSON.stringify(props.modelValue.model)) {
    internal.value = { ...internal.value, model: omit(['isDisabled'], newVal) }
  }
})

const isShowError = computed(() => {
  if (brand.value && !model.value) {
    const sameBrands = props.selectedBrandIds.filter(id => id === brand.value?.id)
    return sameBrands.length > 1
  }
  return false
})

const __returned__ = { props, emit, sortByAvailability, sortByAlpha, brands, models, brand, model, uuidv4, brandId, modelId, internal, isShowError, ref, watch, computed, get omit() { return omit }, get map() { return map }, get clone() { return clone } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
import { ref, Ref, ComputedRef, UnwrapRef } from 'vue'
import { useLoadingState } from './state'

export type Loadable<T> = {
  loading: ComputedRef<boolean>,
  result: Ref<T | UnwrapRef<T>>,
  lastPromise: Ref<Promise<T> & { cancel?: () => void } | null>
  load: (...args: any[]) => Promise<T>
}

export function useLoadable<T = any>(
  callable: any, {
  cancellable = true
} = {}): Loadable<T> {
  const loading = useLoadingState()
  const result = ref<T | null>(null)
  const lastPromise: Ref<Promise<T> & { cancel?: () => void } | null> = ref(null)

  return {
    result,
    loading: loading.loading,
    lastPromise,
    load: (...args: any[]) => {
      if (cancellable && lastPromise.value && lastPromise.value.cancel) {
        lastPromise.value?.cancel?.()
      }

      const promise: Promise<T> = loading.load(
        callable(...args).then((r: UnwrapRef<T>) => {
          result.value = r || null
          return r
        })
      )
      lastPromise.value = promise


      return promise
    }
  }
}
